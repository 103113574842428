import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'hardwareMessage'];
  
  submitForm() {
    let productList = document.querySelectorAll('.product-list > .product > input');
    let hasHardware = false;

    productList.forEach((element) => {
      if (JSON.parse(element.value).type === 'hardware') {
        hasHardware = true;
      }
    });

    if (hasHardware) {
      this.hardwareMessageTarget.classList.remove('hidden');
    } else {
      this.hardwareMessageTarget.classList.add('hidden');
      $.ajax({
        type: 'POST',
        url: this.formTarget.action,
        data: $(this.formTarget).serialize(),
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
        },
        dataType: 'script'
      }).error((e) => {
        toastr.error('There was an issue creating your estimate.');
      });
    }
  }
}
