import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.cartId = this.data.get('cartId');
    this.apiHeaders = {
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content'),
    };
  }

  duplicateCart(e) {
    e.preventDefault();
    $.ajax('/modal/new', {
      method: 'GET',
      dataType: 'script',
      data: {
        data: JSON.stringify({
          action: 'duplicate_cart_or_quote',
          cart_or_quote_type: 'cart',
          cart_or_quote_id: this.cartId,
        }),
      },
      headers: this.apiHeaders,
    }).fail((e) => {
      toastr.error(e.responseJSON.errors);
    });
  }

  deleteCart(e) {
    e.preventDefault();
    $.ajax('/modal/new', {
      method: 'GET',
      dataType: 'script',
      data: { data: JSON.stringify({ action: 'delete_cart', cart_id: this.cartId }) },
      headers: this.apiHeaders,
    }).fail((e) => {
      toastr.error(e.responseJSON.errors);
    });
  }

  activateCart(e) {
    e.preventDefault();
    toastr.info('Activating Cart');
    $.ajax(('/api/activate-cart/' + this.cartId), {
      method: 'POST',
      async: true,
      cache: false,
      timeout: 7000,
      headers: this.apiHeaders,
    }).success(() => {
      window.location.reload();
    }).fail(() => {
      toastr.error('Failed To Activate Cart');
    });
  }

  emptyCart(e) {
    e.preventDefault();
    $.ajax('/modal/new', {
      method: 'GET',
      dataType: 'script',
      data: { data: JSON.stringify({ action: 'empty_cart', cart_id: this.cartId }) },
      headers: this.apiHeaders,
    }).fail((e) => {
      toastr.error(e.responseJSON.errors);
    });
  }

  splitCart(e) {
    e.preventDefault();
    $.ajax('/modal/new', {
      method: 'GET',
      dataType: 'script',
      data: { data: JSON.stringify({ action: 'split_cart', cart_id: this.cartId }) },
      headers: this.apiHeaders,
    }).fail((e) => {
      toastr.error(e.responseJSON.errors);
    });
  }
}
