import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["rugCustomizationData", "bandingData"];

  open_modal(e) {
    e.preventDefault();

    let product_id = e.currentTarget.getAttribute("data-product-id");
    let order_context = e.currentTarget.getAttribute("data-order-context");

    let order_entry_data = {
      order_entry: {
        product_id: product_id,
        order_context: order_context,
      },
    };

    if (e.currentTarget.getAttribute("data-order-type") != null) {
      order_entry_data["order_entry"]["order_type"] = e.currentTarget.getAttribute("data-order-type");
    }

    if (e.currentTarget.getAttribute("data-cart-id") != null) {
      order_entry_data["order_entry"]["cart_id"] = e.currentTarget.getAttribute("data-cart-id");
    }

    if (e.currentTarget.getAttribute("data-cart-item-id") != null) {
      order_entry_data["order_entry"]["cart_item_id"] = e.currentTarget.getAttribute("data-cart-item-id");
    }

    if (e.currentTarget.getAttribute("data-quote-id") != null) {
      order_entry_data["order_entry"]["quote_id"] = e.currentTarget.getAttribute("data-quote-id");
    }

    if (e.currentTarget.getAttribute("data-quote-item-id") != null) {
      order_entry_data["order_entry"]["quote_item_id"] = e.currentTarget.getAttribute("data-quote-item-id");
    }

    if (this.hasRugCustomizationDataTarget) {
      order_entry_data["order_entry"]["customizations"] = this.rugCustomizationDataTarget.querySelector("[name=cart_quote_customization_data]").value;
    }

    if (this.hasBandingDataTarget) {
      order_entry_data["order_entry"]["banding"] = this.bandingDataTarget.querySelector("#banding").checked;
      if (order_entry_data["order_entry"]["banding"]) {
        let selected_finish = this.bandingDataTarget.querySelector("input[name=banding-finish]:checked");

        if (selected_finish == null) {
          toastr.error("If banding is selected, a finish and at least one zone must also be selected");
          return false;
        } else {
          order_entry_data["order_entry"]["banding_finish"] = selected_finish.value;
        }

        order_entry_data["order_entry"]["banding_zones"] = [];
        this.bandingDataTarget.querySelectorAll("input[name=banding-zones]:checked").forEach((element) => {
          order_entry_data["order_entry"]["banding_zones"].push(element.value);
        });

        if (order_entry_data["order_entry"]["banding_zones"].length == 0) {
          toastr.error("If banding is selected, a finish and at least one zone must also be selected");
          return false;
        } else {
          order_entry_data["order_entry"]["banding_zones"] = order_entry_data["order_entry"]["banding_zones"].join(",");
        }
      }
    }

    let form = document.getElementById("edit-quote-form");
    if (form != null) {
      if (document.getElementById("quote_template_select").value !== "no-template") {
        order_entry_data["quote_form_data"] = $(form).serialize();
      }
    }

    $.ajax({
      type: "POST",
      url: "/order-entry",
      data: order_entry_data,
      dataType: "script",
    });
  }

  delete_quote_item(event) {
    let eventElement = event.target;
    let element = eventElement.closest("button");

    toastr.warning("Deleting item from quote. Please wait...");

    $.ajax(element.getAttribute('data-url'), {
      method: "DELETE",
      headers: { 
        "X-CSRF-Token": $("meta[name=csrf-token]").prop("content")
      }
    });
  }
}
